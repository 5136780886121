'use strict';

Gri.module({
  name: 'parallax-beta',
  ieVersion: null,
  $el: $('.parallax-beta'),
  container: '.parallax-beta',
  fn: function () {
    
  }
});
